<template>
  <div class="context">
    <div class="main">
      <el-row :gutter="24">
        <el-col :span="6">
          <div class="avatar-box">
            <div class="bg-top"></div>
            <div class="photo-wrapper text-center">
              <img
                src="https://notes-images-1301074692.cos.ap-guangzhou.myqcloud.com/notesImages/avater.jpg"
              />
              <!-- <img src="https://img.xiaoyou66.com/images/2020/02/20/tTSY.jpg" /> -->
              <!-- <img src="../../assets/images/avater.jpg" /> -->
            </div>
            <div class="info">
              <h1 class="name">ShangGuanyaa</h1>
            </div>
            <div class="data">
              <div class="data-item" v-for="(item, i) in infoData" :key="i">
                <p class="num">{{ item.num }}</p>
                <p class="title">{{ item.title }}</p>
              </div>
            </div>
            <div class="bottom-space"></div>
          </div>
        </el-col>
        <el-col :span="13">
          <div class="tools">
            <div class="left">
              <div>
                <i class="el-icon-s-home"></i>
                <p>首页</p>
              </div>
            </div>
            <div class="line"></div>
            <div class="menu">
              <ul>
                <li v-for="(item, i) in topMenu" :key="i">
                  {{ item.name }}<span> {{ item.total }} </span>
                  <!-- <el-tag size="mini"> {{ item.total }} </el-tag> -->
                </li>
              </ul>
            </div>
          </div>
          <div class="windows" v-for="(item, i) in article" :key="i">
            <h2 class="win-title" @click="openDoc(item)">{{ item.title }}</h2>
            <div class="win-list">
              <div class="win-text">{{ item.text }}</div>
              <div class="win-line"></div>
              <div class="win-date">{{ item.date }}</div>
            </div>
          </div>
        </el-col>
        <el-col :span="5">
          <div class="notice">
            <h4 class="title">公告栏</h4>
            <div class="line"></div>
            <p>目前系统还在开发中~并不是稳定版本，还希望大家积极反馈bug</p>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    infoData: [
      { title: "文 章", num: 21 },
      { title: "日 记", num: 113 },
      { title: "项 目", num: 11 },
    ],
    article: [
      {
        title: "Hadoop 完全搭建(1)",
        text: "这是基于上课材料里的 Word 文档进行优化的 Hadoop 搭建文档，更好地体验。",
        date: "2022-05-24",
      },
      {
        title: "Zookeeper 搭建",
        text: "这是正文文本内容，不知道是什么，总之就是先多写点内容吧，可以撑下去最好，可以撑下去最好",
        date: "2021-12-30",
      },
      {
        title: "2021年度总结",
        text: "这是正文文本内容，不知道是什么，总之就是先多写点内容吧，可以撑下去最好，可以撑下去最好",
        date: "2021-12-30",
      },
      {
        title: "2021年度总结",
        text: "这是正文文本内容，不知道是什么，总之就是先多写点内容吧，可以撑下去最好，可以撑下去最好",
        date: "2021-12-30",
      },
      {
        title: "2021年度总结",
        text: "这是正文文本内容，不知道是什么，总之就是先多写点内容吧，可以撑下去最好，可以撑下去最好",
        date: "2021-12-30",
      },
    ],
    topMenu: [
      { name: "硬件杂谈", total: 29 },
      { name: "个人日常", total: 95 },
      { name: "个人项目", total: 51 },
      { name: "程序人生", total: 36 },
      { name: "各种折腾", total: 64 },
      { name: "实用内容", total: 11 },
      { name: "软件使用", total: 0 },
    ],
  }),
  methods: {
    openDoc(item) {
      if (item.title === "Hadoop 完全搭建(1)") {
        console.log("open new pages.");
        window.open(`config.html`);
      } else if (item.title === "Zookeeper 搭建") {
        console.log("open new pages.");
        window.open(
          "https://doc-files-1301074692.cos.ap-guangzhou.myqcloud.com/HTML/2.Zookeeper%E9%9B%86%E7%BE%A4%E6%90%AD%E5%BB%BA.html"
        );
      } else {
        const h = this.$createElement;
        this.$notify({
          title: "温馨提示",
          message: h("i", { style: "color: teal" }, "文章暂时不开放"),
        });
      }
    },
  },
};
</script>

<style scoped>
.context {
  background-image: url("https://notes-images-1301074692.cos.ap-guangzhou.myqcloud.com/notesImages/tKb5.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.main {
  width: 60%;
  margin: 0 auto;
  padding-top: 20px;
}
.avatar-box {
  width: 100%;
  user-select: none;
}
.avatar-box .bg-top {
  height: 120px;
  border-radius: 15px 15px 0 0;
  background-color: #fff;
  background: url(https://notes-images-1301074692.cos.ap-guangzhou.myqcloud.com/notesImages/y0qR.png)
    no-repeat center center;
  -webkit-background-size: cover;
  background-size: cover;
}
.avatar-box .avatar {
  background-color: #fff;
}
.photo-wrapper {
  background-color: #fff;
  text-align: center;
  width: 100%;
  height: 40px;
}
.photo-wrapper img {
  left: 133px;
  margin: -50px 0 0;
  width: 90px;
  height: 90px;
  border: rgba(255, 255, 255, 0.4) 4px solid;
  border-radius: 50%;
  transition: all 0.3s;
  cursor: pointer;
}
.photo-wrapper img:hover {
  width: 100px;
  height: 100px;
  transform: rotate(360deg);
}
img {
  vertical-align: middle;
  border-style: none;
}
.avatar-box .info .name {
  background-color: #fff;
  font-size: 20px;
  text-align: center;
  font-weight: 400;
  font-family: "Lucida Calligraphy", cursive, serif, sans-serif;
}
.avatar-box .data {
  display: flex;
  padding-top: 30px;
  background-color: #fff;
  justify-content: space-around;
}
.avatar-box .data .data-item {
  width: 30%;
  text-align: center;
}
.avatar-box .data .data-item .num {
  color: #00a1d6;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 10px;
}
.bottom-space {
  width: 100%;
  height: 50px;
  background-color: #fff;
  border-radius: 0 0 15px 15px;
}
.tools {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 88px;
  padding: 10px 0;
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 15px;
}
.tools .left {
  text-align: center;
  width: 60px;
}
.tools .line {
  height: 100%;
  border-left: 1px solid #58bcde54;
}
.tools .menu {
  width: calc(100% - 70px);
  padding-left: 20px;
}
.tools .menu ul {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
}
.tools .menu ul li {
  cursor: pointer;
  padding: 5px 5px;
}
.tools .menu ul li span {
  font-style: normal;
  font-size: 12px;
  display: inline-block;
  background: #73c9e5;
  border-radius: 2px;
  color: #fff;
  margin-left: 1px;
  transform: scale(0.85);
  width: 32px;
  text-align: center;
}
.el-icon-s-home {
  /* background-color: pink; */
  font-size: 30px;
  cursor: pointer;
  color: #00a1d6;
}
.windows {
  position: relative;
  width: 100%;
  height: 350px;
  background-color: rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
  border-radius: 20px;
  color: #fff;
  padding: 10px 15px;
  user-select: none;
  transition: all 1s;
}
.win-title {
  position: absolute;
  top: calc(50% - 13px);
  width: 100%;
  padding-right: 20px;
  text-align: center;
  font-size: 26px;
  transition: all 0.3s;
  cursor: pointer;
}
.win-list {
  opacity: 0;
  position: absolute;
  padding-right: 20px;
  bottom: 30px;
  transition: all 0.3s;
}
.win-line {
  border-top: 1px solid #fff;
  margin: 6px 0;
}
.windows:hover .win-title {
  top: 40%;
}
.windows:hover .win-list {
  opacity: 1;
  bottom: 10px;
}
.notice {
  width: 100%;
  background-color: #fff;
  padding: 10px 10px;
  border-radius: 15px;
}
.notice .line {
  border-top: 1px solid #58bcde54;
  margin: 6px 0;
}
.notice p {
  font-size: 14px;
}
</style>